import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import { Button } from '../../shared/components/button'
import { Modal } from '../../shared/components/modal'
import { Heading2 } from '../../shared/components/typography'

import { SearchForm } from './SearchForm'
import { StSearchFormContainer } from './SearchForm.styled'

export function Search() {
  const [open, setOpen] = React.useState(false)

  if (!process.env.NEXT_PUBLIC_SEARCH_ENABLED) return null

  return (
    <>
      <Button
        size="small"
        variant="secondary"
        aria-label="open search"
        gaEventLabel="open search"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faSearch} />
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <StSearchFormContainer>
          <Heading2>Search</Heading2>
          <SearchForm />
        </StSearchFormContainer>
      </Modal>
    </>
  )
}
